<template>
  <div class="DashBoard">
    <div>
      <Topo :decoded="decoded" @OpenMenuLateral="menuOpen = true"></Topo>

      <ConteudoDash
        :decoded="decoded"
        :irPage="page"
        @resetarPage="resetPage"
      ></ConteudoDash>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
      @cotacaoRapida="chagePage"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './Topo';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import MenuInterno from './MenuInterno';
import { logout } from './../services/logout';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      page: 0,
    };
  },
  components: { Topo, ConteudoDash, MenuRodape, MenuLateral, MenuInterno },
  methods: {
    verificaToken() {
      localStorage.removeItem('idGrupo');
      localStorage.removeItem('idProposta');
      localStorage.removeItem('isZero');

      document.body.className = 'logado';

      const token = localStorage.getItem('user-token');

      if (token) {
        this.decoded = jwt.decode(token);
      }

      this.decoded.id_empresa && this.capturaIdEmpresa();

      // @Alternar empresa 117 para 118
      if (
        ['117', '118', '119'].includes(this.decoded.id_empresa) &&
        this.decoded.tipo == 'Consultor'
      ) {
        localStorage.setItem('idEmpresaDefault', '120');
        logout();
      }
    },
    capturaIdEmpresa() {
      localStorage.setItem('idEmpresa', this.decoded.id_empresa);
    },
    chagePage() {
      this.page = 4;
    },
    resetPage() {
      this.page = 0;
    },
  },
  mounted() {
    this.verificaToken();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.DashBoard {
  background-color: #e9e9ea;
}
.iconeFechar {
  font-size: 28px;
}
.bm-menu {
  background: #373a47;
}
.bm-menu {
  background-color: #3f3f41;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.2s;
  width: 0;
  z-index: 9999;
  color: #fff;
}
.bm-item-list > * {
  display: flex;
  padding: 0.7em;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.bm-item-list > * > i {
  color: #666;
  font-weight: 700;
  margin-left: 10px;
}
.logoBottom {
  margin-top: 100px;
  text-align: center;
  opacity: 0.5;
}
</style>
